import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "../../css/tournamentpage.css";

const TournamentPage = () => {
  const location = useLocation();
  const { numPlayers } = location.state || { numPlayers: 4 };

  const [players, setPlayers] = useState(
    Array.from({ length: numPlayers }, () => ({
      id: "",
      name: "",
      score4: 0,
      score3: 0,
      wins: 0,
      advance: false,
      eliminated: false,
      finalWinner: false,
      winner: false,  // 승리 상태 추가
      loser: false,   // 패배 상태 추가
      buttonsHidden: false, // 버튼 숨김 상태 추가
      isManualInput: false, // 수기 입력 여부 추가
      inputCompleted: false // 수기 입력 완료 여부 추가
    }))
  );

  const [billiardHalls, setBilliardHalls] = useState([]);
  const [playerOptions, setPlayerOptions] = useState(Array(numPlayers).fill([]));
  const [isTournamentStarted, setIsTournamentStarted] = useState(false);
  const [finalWinner, setFinalWinner] = useState(null);
  const [advancedPlayers, setAdvancedPlayers] = useState([]); // 다음 라운드 진출 플레이어
  const [roundTitle, setRoundTitle] = useState(""); // 기본 라운드 제목

  useEffect(() => {
    axios
      .get("/api/billiards")
      .then((response) => {
        setBilliardHalls([...response.data, "기타"]);
      })
      .catch((error) => {
        console.error("Error fetching billiard halls:", error);
      });

    setRoundTitle(getRoundTitle(numPlayers));
  }, [numPlayers]);

  // 라운드 제목 설정 함수
  const getRoundTitle = (numPlayersLeft) => {
    if (numPlayersLeft === 2) return "결승전";
    if (numPlayersLeft === 4) return "준결승전";
    if (numPlayersLeft === 8) return "8강전";
    if (numPlayersLeft === 16) return "16강전";
    if (numPlayersLeft === 32) return "32강전";
    return `${numPlayersLeft}강전`;
  };

  // 당구장 선택 시 플레이어 목록을 불러오는 로직
  const handleSelectHall = (index, hallName) => {
    const updatedPlayers = [...players];
    if (hallName === "기타") {
      updatedPlayers[index] = {
        ...updatedPlayers[index],
        billiard: hallName,
        id: "",
        score4: 0,
        score3: 0,
        isManualInput: true, // 수기 입력 상태 활성화
        inputCompleted: false // 입력 완료 상태 초기화
      };
    } else {
      updatedPlayers[index].isManualInput = false; // 수기 입력 상태 비활성화
      axios
        .get(`/api/players/by-hall?hall=${encodeURIComponent(hallName)}`)
        .then((response) => {
          updatedPlayers[index] = {
            ...updatedPlayers[index],
            billiard: hallName,
            id: "", // 기존 ID를 초기화
          };
          setPlayerOptions((prev) => {
            const newOptions = [...prev];
            newOptions[index] = response.data || [];
            return newOptions;
          });
        })
        .catch((error) => {
          console.error(`Error fetching players for hall ${hallName}:`, error);
        });
    }
    setPlayers(updatedPlayers);
  };

  // 플레이어 선택 시 해당 플레이어의 정보를 가져오는 로직
  const handleSelectPlayer = (index, playerId) => {
    axios
      .get(`/api/players/${encodeURIComponent(playerId)}`)
      .then((response) => {
        const updatedPlayers = [...players];
        updatedPlayers[index] = {
          ...updatedPlayers[index],
          id: playerId, // ID 저장
          name: response.data.name,
          score4: response.data.score4,
          score3: response.data.score3,
          inputCompleted: true // 입력 완료 상태 활성화
        };
        setPlayers(updatedPlayers); // 상태 업데이트
      })
      .catch((error) => {
        console.error(`Error fetching player data for ${playerId}:`, error);
      });
  };

  // 수기 입력 시 값 업데이트
  const handleInputChange = (index, field, value) => {
    const updatedPlayers = [...players];
    updatedPlayers[index] = {
      ...updatedPlayers[index],
      [field]: value,
    };
    setPlayers(updatedPlayers);
  };

  // 수기 입력 완료 시 호출
  const handleManualInputComplete = (index) => {
    const updatedPlayers = [...players];
    updatedPlayers[index].inputCompleted = true; // 입력 완료 상태로 변경
    setPlayers(updatedPlayers);
  };

  const startTournament = () => {
    // ID가 없는 플레이어는 제외하고 토너먼트 시작
    const filteredPlayers = players.filter((player) => player.id !== "");
    setPlayers(filteredPlayers); 
    setIsTournamentStarted(true);
  };

  // 승리 버튼 핸들러
  const handleWin = (playerIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].wins += 1;
    setPlayers(updatedPlayers);
  };

  // 버튼 비활성화 및 다음 라운드 진출 처리
  const handleAdvance = (playerIndex, opponentIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].advance = true;
    updatedPlayers[playerIndex].winner = true;
    updatedPlayers[opponentIndex].eliminated = true;
    updatedPlayers[opponentIndex].loser = true;

    updatedPlayers[playerIndex].buttonsHidden = true;
    updatedPlayers[opponentIndex].buttonsHidden = true;

    setPlayers(updatedPlayers);

    setAdvancedPlayers((prev) => [...prev, updatedPlayers[playerIndex]]);

    alert(`${players[playerIndex].id} 플레이어가 다음 라운드로 진출했습니다!`);
  };

  const handleFinalWin = (playerIndex, opponentIndex) => {
    const updatedPlayers = [...players];
    updatedPlayers[playerIndex].finalWinner = true;
    updatedPlayers[playerIndex].winner = true;
    updatedPlayers[opponentIndex].loser = true;

    updatedPlayers[playerIndex].buttonsHidden = true;
    updatedPlayers[opponentIndex].buttonsHidden = true;

    setPlayers(updatedPlayers);
    setFinalWinner(updatedPlayers[playerIndex]);

    alert(`${players[playerIndex].id} 플레이어가 최종 우승하였습니다!`);
  };

  const handleNextRound = () => {
    setPlayers(advancedPlayers.map(player => ({
      ...player,
      wins: 0,
      advance: false,
      buttonsHidden: false,
      winner: false,
      loser: false,
    })));

    setRoundTitle(getRoundTitle(advancedPlayers.length));
    setAdvancedPlayers([]);
  };

  const renderPlayers = () => {
    return players.reduce((acc, player, index) => {
      if (index % 2 === 0 && players[index + 1]) {
        const opponent = players[index + 1];
        const isFinalRound = players.length === 2;
        acc.push(
          <div key={index / 2} className="match">
            <div className="player">
              <p>{player.name || `Player-${index + 1}`}</p> {/* 플레이어 이름 또는 ID 반영 */}
              <p>ID: {player.id}</p>
              <p>4구 점수: {player.score4}</p>
              <p>3구 점수: {player.score3}</p>
              <p>승리 횟수: {player.wins}</p>
              {player.winner && <p>승리</p>}
              {player.loser && <p>패배</p>}
              {!player.eliminated && !player.buttonsHidden && (
                <>
                  <button onClick={() => handleWin(index)}>
                    승리
                  </button>
                  {player.advance ? (
                    <button disabled>다음 라운드 진출</button>
                  ) : (
                    <button
                      onClick={() =>
                        isFinalRound
                          ? handleFinalWin(index, index + 1)
                          : handleAdvance(index, index + 1)
                      }
                    >
                      {isFinalRound ? "최종 우승" : "다음 라운드 진출"}
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="vs">VS</div>
            <div className="player">
              <p>{opponent.name || `Player-${index + 2}`}</p>
              <p>ID: {opponent.id}</p>
              <p>4구 점수: {opponent.score4}</p>
              <p>3구 점수: {opponent.score3}</p>
              <p>승리 횟수: {opponent.wins}</p>
              {opponent.winner && <p>승리</p>}
              {opponent.loser && <p>패배</p>}
              {!opponent.eliminated && !opponent.buttonsHidden && (
                <>
                  <button onClick={() => handleWin(index + 1)}>
                    승리
                  </button>
                  {opponent.advance ? (
                    <button disabled>다음 라운드 진출</button>
                  ) : (
                    <button
                      onClick={() =>
                        isFinalRound
                          ? handleFinalWin(index + 1, index)
                          : handleAdvance(index + 1, index)
                      }
                    >
                      {isFinalRound ? "최종 우승" : "다음 라운드 진출"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        );
      }
      return acc;
    }, []);
  };

  return (
    <div className="tournament-container">
      <h1>당구장 토너먼트</h1>
      {!isTournamentStarted ? (
        <div className="players-input">
          {players.map((player, index) => (
            <div key={index} className="player-input">
              <select
                onChange={(e) => handleSelectHall(index, e.target.value)}
                value={player.billiard || ""}
              >
                <option value="">당구장을 선택하세요</option>
                {billiardHalls.map((hall, i) => (
                  <option key={i} value={hall}>
                    {hall}
                  </option>
                ))}
              </select>

              {player.isManualInput && !player.inputCompleted ? (
                <>
                  <input
                    type="text"
                    placeholder="플레이어 ID"
                    value={player.id}
                    onChange={(e) => handleInputChange(index, "id", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="4구 점수"
                    value={player.score4}
                    onChange={(e) => handleInputChange(index, "score4", e.target.value)}
                  />
                  <input
                    type="number"
                    placeholder="3구 점수"
                    value={player.score3}
                    onChange={(e) => handleInputChange(index, "score3", e.target.value)}
                  />
                  <button onClick={() => handleManualInputComplete(index)}>
                    입력 완료
                  </button>
                </>
              ) : player.billiard === "기타" && player.inputCompleted ? (
                <div>
                  <p>ID: {player.id}</p>
                  <p>4구 점수: {player.score4}</p>
                  <p>3구 점수: {player.score3}</p>
                </div>
              ) : (
                player.billiard &&
                playerOptions[index].length > 0 && (
                  <select
                    onChange={(e) => handleSelectPlayer(index, e.target.value)}
                    value={player.id || ""}
                  >
                    <option value="">플레이어를 선택하세요</option>
                    {playerOptions[index].map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.id}
                      </option>
                    ))}
                  </select>
                )
              )}

              {/* 당구장에서 선택된 플레이어의 정보 표시 */}
              {!player.isManualInput && player.inputCompleted && (
                <div>
                  <p>ID: {player.id}</p>               
                  <p>4구 점수: {player.score4}</p>
                  <p>3구 점수: {player.score3}</p>
                </div>
              )}
            </div>
          ))}
          <button onClick={startTournament}>토너먼트 시작</button>
        </div>
      ) : (
        <div>
          <div className="round-title">
            <h2>{roundTitle}</h2>
            <input
              type="text"
              value={roundTitle}
              onChange={(e) => setRoundTitle(e.target.value)}
              placeholder="라운드 제목 수정"
            />
          </div>

          {renderPlayers()}

          {advancedPlayers.length > 0 && (
            <div>
              <h3>다음 라운드 진출 플레이어:</h3>
              {advancedPlayers.map((player, idx) => (
                <p key={idx}>{player.id}</p>
              ))}
            </div>
          )}

          {advancedPlayers.length > 0 && (
            <button onClick={handleNextRound}>다음 라운드 시작</button>
          )}
        </div>
      )}
      {finalWinner && (
        <div className="final-winner">
          <h2>최종 우승자:</h2>
          <p>ID: {finalWinner.id}</p>
          <p>4구 점수: {finalWinner.score4}</p>
          <p>3구 점수: {finalWinner.score3}</p>
        </div>
      )}
    </div>
  );
};

export default TournamentPage;
