import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const HandicapScorePage = () => {
  const [scores, setScores] = useState([]);
  const [user, setUser] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const navigate = useNavigate();

  // 핸디 점수 목록 가져오기
  const fetchScores = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
      const response = await axios.get('/api/handicap-scores', config);
      setScores(response.data);
    } catch (error) {
      console.error('Error fetching scores:', error.message);
    }
  };

  // 사용자 정보 가져오기
  const fetchUser = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get('/api/user', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user:', error.message);
      }
    }
  };

  useEffect(() => {
    fetchUser();
    fetchScores();
  }, []);

  // 점수 등록 페이지로 이동
  const handleWriteScore = () => {
    if (!user) {
      alert('로그인 후 점수를 등록할 수 있습니다.');
      return;
    }
    navigate('/handicap-scores/new');
  };

  // 점수 삭제 처리
  const handleDeleteScore = async (scoreId) => {
    if (!window.confirm('정말로 삭제하시겠습니까?')) return;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('로그인 후 삭제할 수 있습니다.');
        return;
      }

      await axios.delete(`/api/handicap-scores/${scoreId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      alert('핸디 점수가 삭제되었습니다.');
      setScores(scores.filter((score) => score.id !== scoreId));
    } catch (error) {
      console.error('Error deleting score:', error.message);
    }
  };

  // 정렬 처리
  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  
    const sorted = [...scores].sort((a, b) => {
      const aValue = a[key] ? a[key].toString() : ''; // 값이 없을 경우 빈 문자열로 처리
      const bValue = b[key] ? b[key].toString() : '';
  
      if (key === 'id') {
        return direction === 'asc' ? a.id - b.id : b.id - a.id;
      } else if (key === 'author' || key === 'status' || key === 'date') {
        return direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else if (!isNaN(aValue) && !isNaN(bValue)) {
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      }
      return 0;
    });
  
    setScores(sorted);
  };
  

  // 정렬 화살표 표시
  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲';
  };

  return (
    <div>
      <h1>핸디점수등록</h1>

      {/* 점수 등록 버튼 (로그인한 사용자만 표시) */}
      {user && (
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button onClick={handleWriteScore}>점수 등록</button>
        </div>
      )}

      <table>
        <thead>
          <tr>
            <th onClick={() => sortData('id')}>번호 {renderArrow('id')}</th>
            <th onClick={() => sortData('author')}>글쓴이 {renderArrow('author')}</th>
            <th onClick={() => sortData('score4')}>4구 점수 {renderArrow('score4')}</th>
            <th onClick={() => sortData('score3')}>3구 점수 {renderArrow('score3')}</th>
            <th onClick={() => sortData('highRun4')}>4구 하이런 {renderArrow('highRun4')}</th>
            <th onClick={() => sortData('highRun3')}>3구 하이런 {renderArrow('highRun3')}</th>
            <th onClick={() => sortData('date')}>작성날짜 {renderArrow('date')}</th>
            <th onClick={() => sortData('status')}>진행사항 {renderArrow('status')}</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {scores.length > 0 ? (
            scores.map((score, index) => (
              <tr key={score.id}>
                <td>{sortConfig.direction === 'asc' ? index + 1 : scores.length - index}</td>
                <td>{score.author}</td>
                <td>{score.score4}</td>
                <td>{score.score3}</td>
                <td>{score.highRun4}</td>
                <td>{score.highRun3}</td>
                <td>{new Date(score.date).toLocaleDateString()}</td>
                <td>{score.status || '진행 중'}</td>
                <td>
                  {score.status !== '등록 완료' && score.author === user?.username && (
                    <>
                      <button onClick={() => navigate(`/handicap-scores/edit/${score.id}`)}>수정</button>
                      <button onClick={() => handleDeleteScore(score.id)}>삭제</button>
                    </>
                  )}
                  {user?.role === 'admin' && (
                    <button onClick={() => handleDeleteScore(score.id)}>삭제</button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">등록된 핸디점수가 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default HandicapScorePage;
