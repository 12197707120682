import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import RankingsTable from "./RankingsTable";
import Pagination from "./Pagination";
import "../css/base.css";
import "../css/searchbar.css";

const MainContent = () => {
  const [rankings, setRankings] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchField, setSearchField] = useState("id");
  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "ascending" });
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredRankings, setFilteredRankings] = useState([]);
  const itemsPerPage = 100;

  useEffect(() => {
    axios
      .get("/api/rankings")
      .then((response) => {
        setRankings(response.data);
        setFilteredRankings(response.data); // 초기에는 전체 데이터를 표시
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSearchClick = () => {
    setCurrentPage(1); // 검색 후 첫 페이지로 이동
    const filteredData = rankings.filter((rank) => {
      const fieldValue = rank[searchField];
      return fieldValue
        ? fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
        : false;
    });
    setFilteredRankings(filteredData);
  };

  const sortedRankings = useMemo(() => {
    const sorted = [...filteredRankings].sort((a, b) => {
      if (sortConfig.key === "number") {
        const aIndex = rankings.indexOf(a) + 1;
        const bIndex = rankings.indexOf(b) + 1;
        return sortConfig.direction === "ascending" ? aIndex - bIndex : bIndex - aIndex;
      } else {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      }
    });
    return sorted;
  }, [filteredRankings, sortConfig, rankings]);

  const currentItems = useMemo(() => {
    return sortedRankings.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [sortedRankings, currentPage, itemsPerPage]);

  const totalPages = useMemo(() => Math.ceil(sortedRankings.length / itemsPerPage), [
    sortedRankings.length,
    itemsPerPage,
  ]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="main-content">
      <div className="search-bar-container">
        <div className="search-bar">
          <select
            className="search-bar__select"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          >
            <option value="id">별칭</option>
            <option value="score4">4구 점수</option>
            <option value="score3">3구 점수</option>
            <option value="highrun4">4구 하이런</option>
            <option value="highrun3">3구 하이런</option>
            <option value="birthYear">생년</option>
            <option value="region">지역</option>
            <option value="billiards">당구장이름</option>
          </select>
          <input
            className="search-bar__input"
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearchClick}>검색</button>
        </div>
      </div>
      <RankingsTable
        currentItems={currentItems}
        sortConfig={sortConfig}
        requestSort={requestSort}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={setCurrentPage}
      />
    </div>
  );
};

export default MainContent;
