import React, { useState, useEffect } from "react";
import { Route, Routes, Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import axios from 'axios';

import PrivateRoute from './Admin/PrivateRoute'; // 수정된 PrivateRoute 임포트

// Admin 관련 컴포넌트
import AdminNoticeManagePage from "./Admin/AdminNoticeManagePage";
import AdminNoticeEditPage from './Admin/AdminNoticeEditPage';
import AdminRegionManagePage from './Admin/AdminRegionManagePage'; // 경로를 맞게 설정하세요
import MembersList from './Admin/MembersList'; // MembersList 컴포넌트 경로 임포트
import AdminHandicapManagePage from './Admin/AdminHandicapManagePage'; // 추가

//Page 관련 컴포넌트
import gy from './Pages/gy'; // 미리 생성된 페이지 임포트

// Auth 관련 컴포넌트
import SignupPage from "./Admin/SignupPage";
import LoginPage from "./Admin/LoginPage";
import RequestResetPasswordPage from "./Admin/RequestResetPasswordPage";
import ResetPasswordPage from "./Admin/ResetPasswordPage";
import FindUsernamePage from "./Admin/FindUsernamePage";
import UserProfile from "./Admin/UserProfile";

// Main 관련 컴포넌트
import MainContent from "./MainContent";
import NoticeListPage from './Category/NoticeListPage';
import NoticeDetailPage from './Category/NoticeDetailPage';
import FreeBoardPage from "./Category/FreeBoardPage";
import FreeBoardWritePage from './Category/FreeBoardWritePage';
import FreeBoardDetailPage from './Category/FreeBoardDetailPage';
import FreeBoardEditPage from './Category/FreeBoardEditPage';
import RegionPage from "./Category/RegionPage";
import HandicapScorePage from "./Category/HandicapScorePage";
import HandicapScoreWritePage from './Category/HandicapScoreWritePage'; // PremiumRoomWritePage 임포트
import HandicapScoreEditPage from './Category/HandicapScoreEditPage';  // 수정 페이지 컴포넌트

// Tournament 관련 컴포넌트
import TournamentSetupPage from "./Category/TournamentSetupPage";
import TournamentPage from "./Category/TournamentPage";

// Ad 관련 컴포넌트
import AdScreen from "./Ad/AdScreen";
import AdScreen2 from "./Ad/AdScreen2";
import AdScreen3 from "./Ad/AdScreen3";

// 공통 컴포넌트
import NavContainer from "./NavContainer";

// 공통 스타일 임포트
import "../css/base.css";
import "../css/pagination.css";
import "../css/table.css";
import '../css/signup.css';

// NotFound 컴포넌트 정의
const NotFound = () => <h2>404 - 페이지를 찾을 수 없습니다.</h2>;

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

// 로그인 상태 확인 및 관리자 여부 체크
useEffect(() => {
  const token = localStorage.getItem('token');

  if (token) {
    axios.get('/api/check-admin', {
      headers: {
        Authorization: `Bearer ${token}`, // 토큰 전송
      },
    })
    .then((response) => {
      setIsAdmin(response.data.isAdmin);
      setIsLoggedIn(true); // 로그인 상태 유지
    })
    .catch((error) => {
      console.error('Error checking admin status:', error);
      
      // 유효하지 않은 토큰이거나 만료된 경우 로그아웃 처리
      if (error.response && error.response.status === 403) {
        handleLogout();
      }
    });
  } else {
    setIsLoggedIn(false);
    setIsAdmin(false);
  }
}, []); // 처음 마운트 시 한 번만 실행되도록 빈 배열로 설정

// 로그아웃 처리
const handleLogout = () => {
  localStorage.removeItem('token');
  setIsLoggedIn(false);
  setIsAdmin(false); // 로그아웃 시 관리자 상태 초기화
  navigate('/');
};

// 로그인 처리
const handleLogin = async () => {
  try {
    const response = await axios.post('/api/login', { username, password });
    localStorage.setItem('token', response.data.token);
    setIsLoggedIn(true);
    setIsAdmin(response.data.isAdmin);
    navigate('/'); // 로그인 후 메인 페이지로 리다이렉트
  } catch (error) {
    console.error('Error logging in:', error);
    alert('로그인 실패');
  }
};

  return (
    <div className="App main-container">
      <header className="App-header">
        <h2 className="h2-control">
          <Link to="/">CHIKKA</Link>
        </h2>

        {/* 공통 네비게이션 */}
        <nav className="category-nav">
          <Link to="/notice">공지사항</Link>
          <Link to="/tournament-setup">당구장대회</Link>
          <Link to="/region">지역별당구장</Link>
          <Link to="/handicap-scores">핸디점수등록</Link>
          <Link to="/free-board">자유게시판</Link>
        </nav>

        {/* 로그인, 로그아웃, 프로필 */}
        <div className="auth-buttons-container">
          {isLoggedIn ? (
            <>
              <Link to="/profile">
                <button className="profile-button">회원정보</button>
              </Link>
              {isAdmin && (
                <>
                  <Link to="/admin/notice-manage">
                    <button className="admin-button">공지사항 관리</button>
                  </Link>
                  <Link to="/admin/region-manage">
                    <button className="admin-button">당구장 관리</button>
                  </Link>
                  <Link to="/admin/members">  {/* 회원 목록 관리 버튼 추가 */}
                    <button className="admin-button">회원목록 관리</button>
                  </Link>
                  <Link to="/admin/handicap-scores">
                 <button className="admin-button">핸디점수관리</button> {/* 핸디점수 관리 버튼 추가 */}
                  </Link>
                </>
              )}
              <button className="logout-button" onClick={handleLogout}>로그아웃</button>
            </>
          ) : (
            <div className="auth-buttons">
              <div className="login-container">
                <input 
                  type="text" 
                  placeholder="아이디" 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)} 
                />
                <input 
                  type="password" 
                  placeholder="비밀번호" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                />
                <button onClick={handleLogin}>로그인</button>
              </div>
              <Link to="/find-username">
                <button>아이디 찾기</button>
              </Link>
              <Link to="/request-reset-password">
                <button>비밀번호 재설정</button>
              </Link>
              <Link to="/signup">
                <button>회원가입</button>
              </Link>
            </div>
          )}
        </div>
      </header>

      {/* 광고 배너 */}
      <div className="left-ad-container">
        <a href="https://link.coupang.com/a/bK0lUB" target="_blank" rel="noopener noreferrer">
          <img
            src="https://static.coupangcdn.com/image/affiliate/banner/d406ea5b17220d9ce4c0bbd312a22567@2x.jpg"
            alt="휴넬 1+1 여름 아이스 넥밴드 쿨링 넥쿨러"
            width="120"
            height="240"
          />
        </a>
      </div>

      <div className="right-ad-container">
        <a href="https://link.coupang.com/a/bK0vEU" target="_blank" rel="noopener noreferrer">
          <img
            src="https://img2c.coupangcdn.com/image/affiliate/banner/f0ab7ce5882e7689627f77fc3a7514fe@2x.jpg"
            alt="카본 고급 당구큐대 개인 큐 상대 하대 세트 고급 당구 큐대, 카. [옵션10]"
            width="120"
            height="240"
          />
        </a>
      </div>

    {/* NavContainer 추가 */}
<div className="nav-container-wrapper">
  {location.pathname === "/" && (  // 루트 경로에서만 NavContainer를 보여줌
    <>
      <NavContainer
        path="/ad"
        navImage={process.env.PUBLIC_URL + "/images/ad/P3.gif"}
        navText={["작당당구장", "천호동 288-28"]}
      />
      <NavContainer
        path="/ad2"
        navImage={process.env.PUBLIC_URL + "/images/ad/P4.gif"}
        navText={["다른 광고", "다른 주소"]}
      />
      <NavContainer
        path="/ad3"
        navImage={process.env.PUBLIC_URL + "/images/ad/P5.gif"}
        navText={["다른 광고2", "다른 주소2"]}
      />
    </>
  )}
</div>

      {/* 메인 컨텐츠 */}
      <main>
      <Routes>
        {/* 일반 사용자 페이지 */}
        <Route path="/" element={<MainContent />} />
        <Route path="/notice" element={<NoticeListPage />} />
        <Route path="/notice/:id" element={<NoticeDetailPage />} />
        <Route path="/tournament-setup" element={<TournamentSetupPage />} />
        <Route path="/tournament" element={<TournamentPage />} />
        <Route path="/region" element={<RegionPage />} />
        <Route path="/handicap-scores" element={<HandicapScorePage />} />
        <Route path="/handicap-scores/new" element={<HandicapScoreWritePage />} />
        <Route path="/handicap-scores/edit/:id" element={<HandicapScoreEditPage />} />
        <Route path="/free-board" element={<FreeBoardPage />} />
        <Route path="/free-board/write" element={<FreeBoardWritePage />} />
        <Route path="/free-board/:id" element={<FreeBoardDetailPage />} />
        <Route path="/free-board/edit/:id" element={<FreeBoardEditPage />} />
        <Route path="/free-board/new" element={<FreeBoardWritePage />} />

        {/* 사용자 프로필 및 인증 페이지 */}
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/request-reset-password" element={<RequestResetPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="/find-username" element={<FindUsernamePage />} />

        {/* 광고 페이지 */}
        <Route path="/ad" element={<AdScreen adName="ad1" />} />
        <Route path="/ad2" element={<AdScreen2 adName="ad2" />} />
        <Route path="/ad3" element={<AdScreen3 adName="ad3" />} />

        {/* 관리자 페이지 */}
        <Route path="/admin/notice-manage" element={<PrivateRoute element={AdminNoticeManagePage} isAdmin={isAdmin} />} />
        <Route path="/admin/notice/edit/:id" element={<PrivateRoute element={AdminNoticeEditPage} isAdmin={isAdmin} />} />
        <Route path="/admin/notice/new" element={<PrivateRoute element={AdminNoticeEditPage} isAdmin={isAdmin} />} />
        <Route path="/admin/region-manage" element={<PrivateRoute element={AdminRegionManagePage} isAdmin={isAdmin} />} />
        <Route path="/admin/members" element={<PrivateRoute element={MembersList} isAdmin={isAdmin} />} /> {/* 회원 목록 경로 설정 */}
        <Route path="/admin/handicap-scores" element={<PrivateRoute element={AdminHandicapManagePage} isAdmin={isAdmin} />} />        
        
        {/* 리디렉션 */}
        <Route path="/notice/edit/:id" element={<Navigate replace to="/admin/notice/edit/:id" />} />
        <Route path="/notice/new" element={<Navigate replace to="/admin/notice/new" />} />   

        {/* 동적 라우트 매칭 */}
        <Route path="/gy" element={<gy />} />

        {/* NotFound Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      </main>
    </div>
  );
};

export default App;
