import React, { useState } from 'react';
import axios from 'axios';

const RequestResetPasswordPage = () => {
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('비밀번호가 일치하지 않습니다.');
      return;
    }
    try {
      await axios.post('/api/reset-password', { username, phone, newPassword });
      setMessage('비밀번호 재설정 성공');
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setMessage('비밀번호 재설정 실패');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>비밀번호 재설정</h2>
      {message && <p>{message}</p>}
      <label>
        아이디:
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </label>
      <label>
        전화번호:
        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
      </label>
      <label>
        새 비밀번호:
        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
      </label>
      <label>
        비밀번호 확인:
        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
      </label>
      <button type="submit">재설정</button>
    </form>
  );
};

export default RequestResetPasswordPage;
