import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const HandicapScoreEditPage = () => {
  const { id } = useParams(); // URL에서 score ID를 추출
  const [scoreData, setScoreData] = useState({
    score4: '',
    score3: '',
    highRun4: '',
    highRun3: ''
  });
  const [loading, setLoading] = useState(true); // 데이터 로딩 상태
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // 기존 점수를 불러오는 useEffect
  useEffect(() => {
    const fetchScore = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Fetching score data for ID:', id); // API 호출 전에 로그 출력

        const response = await axios.get(`/api/handicap-scores/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        console.log('Fetched score data:', response.data); // API 응답 데이터 확인용 로그

        if (response.data) {
          // 불러온 데이터를 입력 필드에 채움
          setScoreData({
            score4: response.data.score4?.toString() || '',
            score3: response.data.score3?.toString() || '',
            highRun4: response.data.highRun4?.toString() || '',
            highRun3: response.data.highRun3?.toString() || '',
          });
        } else {
          setError('점수를 불러오는 중 오류가 발생했습니다.');
        }

        setLoading(false); // 데이터 로딩 완료
      } catch (error) {
        console.error('Error fetching score data:', error); // 오류 로그 출력
        setError('점수를 불러오는 중 오류가 발생했습니다.');
        setLoading(false);
      }
    };

    fetchScore();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setScoreData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedScoreData = {
      score4: parseInt(scoreData.score4, 10),
      score3: parseInt(scoreData.score3, 10),
      highRun4: parseInt(scoreData.highRun4, 10),
      highRun3: parseInt(scoreData.highRun3, 10)
    };

    console.log('Submitting form data:', updatedScoreData);  // 전송 데이터 확인용 로그

    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/handicap-scores/${id}`, updatedScoreData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('점수가 수정되었습니다.');
      navigate('/handicap-scores');
    } catch (error) {
      console.error('Error updating score:', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.message : '수정 중 오류가 발생했습니다.');
    }
  };

  if (loading) {
    return <p>로딩 중...</p>;
  }

  return (
    <div>
      <h1>핸디 점수 수정</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>4구 점수: </label>
          <input
            type="number"
            name="score4"
            value={scoreData.score4}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>3구 점수: </label>
          <input
            type="number"
            name="score3"
            value={scoreData.score3}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>4구 하이런: </label>
          <input
            type="number"
            name="highRun4"
            value={scoreData.highRun4}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>3구 하이런: </label>
          <input
            type="number"
            name="highRun3"
            value={scoreData.highRun3}
            onChange={handleChange}
            required
          />
        </div>   
        <button type="submit">수정 완료</button>
      </form>
    </div>
  );
};

export default HandicapScoreEditPage;
