import React, { useState } from 'react';
import axios from 'axios';
import '../../css/signup.css'; // CSS 파일 임포트

const FindUsernamePage = () => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/find-username', { phone, email });
      console.log('Find username response:', response.data);
      setMessage(`아이디: ${response.data.username}`);
    } catch (error) {
      console.error('Error finding username:', error);
      setMessage('아이디 찾기 실패');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="signup-form">
      <h2>아이디 찾기</h2>
      {message && <p className="message">{message}</p>}
      <label>
        전화번호:
        <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
      </label>
      <label>
        이메일:
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </label>
      <button type="submit">찾기</button>
    </form>
  );
};

export default FindUsernamePage;
