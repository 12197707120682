import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../css/noticePage.css'; // CSS 파일을 임포트

const NoticeListPage = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [sortedAnnouncements, setSortedAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('/api/announcements');
        setAnnouncements(response.data);
        setSortedAnnouncements(response.data); // 초기 데이터를 sortedAnnouncements에도 저장
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements();
  }, []);

  // 정렬 함수
  const sortAnnouncements = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...announcements].sort((a, b) => {
      if (key === 'date') {
        return direction === 'asc'
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      } else if (key === 'title') {
        return direction === 'asc'
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
      }
    });

    setSortedAnnouncements(sorted); // 정렬된 데이터를 UI에만 반영
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/announcements/${id}`);
      setAnnouncements((prev) => prev.filter((a) => a.id !== id));
      setSortedAnnouncements((prev) => prev.filter((a) => a.id !== id)); // 삭제 후 정렬된 데이터도 업데이트
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲'; // 기본적으로 오름차순 화살표를 표시
  };

  return (
    <div className="notice-container">
      <h2 className="notice-title">공지사항 목록</h2>
      <table className="notice-table">
        <thead>
          <tr className="notice-tr">
            <th className="notice-th" onClick={() => sortAnnouncements('id')}>
              번호 {renderArrow('id')}
            </th>
            <th className="notice-th" onClick={() => sortAnnouncements('title')}>
              제목 {renderArrow('title')}
            </th>
            <th className="notice-th" onClick={() => sortAnnouncements('date')}>
              날짜 {renderArrow('date')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedAnnouncements.map((announcement, index) => (
            <tr key={announcement.id} className="notice-tr">
              {/* 정렬 기준에 따라 번호도 함께 재정렬 */}
              <td className="notice-td">{sortConfig.direction === 'asc' ? index + 1 : sortedAnnouncements.length - index}</td>
              <td className="notice-td">
                <Link to={`/notice/${announcement.id}`} className="notice-link">
                  {announcement.title}
                </Link>
              </td>
              <td className="notice-td">{new Date(announcement.date).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NoticeListPage;
