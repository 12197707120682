import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../css/UserProfile.css';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState('');
  const [updatedPhone, setUpdatedPhone] = useState('');
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [password, setPassword] = useState(''); // 비밀번호 상태 추가
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      const response = await axios.get('/api/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setUser(response.data);
        setUpdatedUsername(response.data.username);
        setUpdatedPhone(response.data.phone);
        setUpdatedEmail(response.data.email);
      } else {
        setErrorMessage('사용자 정보를 가져오지 못했습니다.');
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      setErrorMessage('사용자 정보를 가져오는 데 실패했습니다.');
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const deleteUser = async () => {
    const confirmed = window.confirm('정말로 회원 탈퇴하시겠습니까?');
    if (!confirmed) return;

    if (!password) {
      alert('비밀번호를 입력해주세요.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      // 회원 탈퇴 요청과 함께 비밀번호도 전송
      const response = await axios.delete('/api/user', {
        data: { password },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert('회원 탈퇴가 완료되었습니다.');
        // 토큰 삭제 후 메인 페이지로 리다이렉트 (로그아웃 처리)
        localStorage.removeItem('token');
        navigate('/'); // 메인 페이지로 이동
        window.location.reload(); // 페이지 강제 새로고침으로 로그아웃을 확실히 처리
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setErrorMessage('회원 탈퇴에 실패했습니다. 비밀번호를 확인해주세요.');
    }
  };

  const updateUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('로그인이 필요합니다.');
        return;
      }

      const response = await axios.put(
        '/api/user',
        {
          username: updatedUsername,
          phone: updatedPhone,
          email: updatedEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUser(response.data);
      setIsEditing(false);
      alert('회원 정보가 수정되었습니다.');
    } catch (error) {
      console.error('Error updating user:', error);
      setErrorMessage('회원 정보 수정에 실패했습니다.');
    }
  };

  return (
    <div className="user-profile-container">
      <h2>회원 정보</h2>
      {errorMessage && <p>{errorMessage}</p>}
      {user ? (
        <div>
          {isEditing ? (
            <div>
              <label>
                아이디:
                <input
                  type="text"
                  value={updatedUsername}
                  onChange={(e) => setUpdatedUsername(e.target.value)}
                />
              </label>
              <label>
                전화번호:
                <input
                  type="text"
                  value={updatedPhone}
                  onChange={(e) => setUpdatedPhone(e.target.value)}
                />
              </label>
              <label>
                이메일:
                <input
                  type="text"
                  value={updatedEmail}
                  onChange={(e) => setUpdatedEmail(e.target.value)}
                />
              </label>
              <button onClick={updateUser}>저장</button>
              <button className="cancel-btn" onClick={() => setIsEditing(false)}>취소</button>
            </div>
          ) : (
            <div>
              <p>아이디: {user.username}</p>
              <p>전화번호: {user.phone}</p>
              <p>이메일: {user.email}</p>
              <label>
                비밀번호 확인:
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              {/* 포인트 표시 */}
              <p>포인트: {user.points || 0}</p>
              <button onClick={() => setIsEditing(true)}>수정</button>
              <button className="cancel-btn" onClick={deleteUser}>회원 탈퇴</button>
            </div>
          )}
        </div>
      ) : (
        <p className="loading-message">회원 정보를 불러오는 중입니다...</p>
      )}
    </div>
  );
};

export default UserProfile;
