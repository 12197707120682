import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import "../../css/ad.css";

const AdScreen = ({ adName }) => {
  const [adContent, setAdContent] = useState({
    text: "",
    imageUrl: "",
    linkUrl: "",
  });
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isFlipped, setIsFlipped] = useState(false);
  const [keysPressed, setKeysPressed] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/adContents.json");
        setAdContent(response.data[adName] || { text: "", imageUrl: "", linkUrl: "" });
      } catch (error) {
        console.error("Error fetching ad content:", error);
      }
    };

    fetchData();
  }, [adName]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      setKeysPressed((prevKeys) => ({ ...prevKeys, [e.key]: true }));

      setPosition((prevPosition) => {
        let newTop = prevPosition.top;
        let newLeft = prevPosition.left;

        if (keysPressed["i"]) newTop = Math.max(newTop - 10, 0);
        if (keysPressed["k"])
          newTop = Math.min(newTop + 10, window.innerHeight - 200);
        if (keysPressed["j"]) newLeft = Math.max(newLeft - 10, 0);
        if (keysPressed["l"])
          newLeft = Math.min(newLeft + 10, window.innerWidth - 200);

        if (keysPressed["d"] && keysPressed["p"]) {
          const newIsFlipped = !isFlipped;
          setIsFlipped(newIsFlipped);
        }

        return { top: newTop, left: newLeft };
      });
    };

    const handleKeyUp = (e) => {
      setKeysPressed((prevKeys) => ({ ...prevKeys, [e.key]: false }));
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isFlipped, keysPressed]);

  const { text, imageUrl, linkUrl } = adContent;

  return (
    <div className="ad-container">
      <h2>{adName}</h2>
      <div className="ad-content">
        <p>{text}</p>
        <a href={linkUrl}>
          <img
            src={process.env.PUBLIC_URL + imageUrl} // PUBLIC_URL 사용
            alt="광고 이미지"
            style={{
              position: "absolute",
              top: `${position.top}px`,
              left: `${position.left}px`,
              transform: isFlipped ? "scaleX(-1)" : "scaleX(1)",
            }}
          />
        </a>
      </div>
    </div>
  );
};

AdScreen.propTypes = {
  adName: PropTypes.oneOf(["ad1", "ad2", "ad3"]).isRequired,
};

export default AdScreen;
