import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const FreeBoardDetailPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const navigate = useNavigate();

  // 게시글 불러오는 함수
  const fetchPost = async (postId) => {
    try {
      setLoading(true); // 로딩 시작
      const response = await axios.get(`/api/freeboard/${postId}`);
      setPost(response.data);
      checkUserPermissions(response.data.author);
    } catch (error) {
      console.error('게시글을 불러오는 중 오류가 발생했습니다.', error);
      setError('게시글을 불러오는 중 문제가 발생했습니다.');
    } finally {
      setLoading(false); // 로딩 종료
    }
  };

  // 댓글 불러오는 함수
  const fetchComments = async (postId) => {
    try {
      const response = await axios.get(`/api/freeboard/${postId}/comments`);
      setComments(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setComments([]);
      } else {
        console.error('댓글을 불러오는 중 오류가 발생했습니다.', error);
        setError('댓글을 불러오는 중 문제가 발생했습니다.');
      }
    }
  };

// 작성자 및 관리자 여부 확인 함수
const checkUserPermissions = (author) => {
  const token = localStorage.getItem('token');
  if (token) {
    axios
    .get('/api/user', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const user = response.data;
      console.log('API 응답:', user); // 사용자 정보 출력
      setCurrentUser(user.username);
      setIsAuthor(user.username === author); // 작성자 여부 확인
      setIsAdmin(user.role === 'admin'); // 관리자 여부 확인
    })
    .catch((error) => {
      console.error('사용자 확인 중 오류가 발생했습니다.', error);
    });  
  }
};

  // 처음에 게시글과 댓글 불러오기
  useEffect(() => {
    fetchPost(id);
    fetchComments(id);
  }, [id]);

  // 게시글 삭제 핸들러
  const handleDeletePost = async () => {
    const confirmed = window.confirm('정말로 이 게시글을 삭제하시겠습니까?');
    if (!confirmed) return;

    if (!isAuthor && !isAdmin) {
      alert('게시글을 삭제할 권한이 없습니다.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/freeboard/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('게시글이 삭제되었습니다.');
      navigate('/free-board');
    } catch (error) {
      console.error('게시글 삭제 중 오류가 발생했습니다.', error);
      alert('게시글 삭제 중 문제가 발생했습니다.');
    }
  };

  // 게시글 수정 핸들러
  const handleEditPost = () => {
    if (!isAuthor && !isAdmin) {
      alert('게시글을 수정할 권한이 없습니다.');
      return;
    }
    navigate(`/free-board/edit/${id}`); // 수정 페이지로 이동
  };

  // 댓글 추가 핸들러
  const handleAddComment = async () => {
    if (!newComment.trim()) {
      alert('댓글 내용을 입력해주세요.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert('로그인 후 댓글을 작성할 수 있습니다.');
      return;
    }

    try {
      await axios.post(
        `/api/freeboard/${id}/comments`,
        { content: newComment },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchComments(id);
      setNewComment('');
    } catch (error) {
      console.error('댓글 추가 중 오류가 발생했습니다.', error);
      setError('댓글 추가 중 문제가 발생했습니다.');
    }
  };

  // 댓글 삭제 핸들러
  const handleDeleteComment = async (commentId) => {
    const confirmed = window.confirm('정말로 이 댓글을 삭제하시겠습니까?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/freeboard/${id}/comments/${commentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setComments(comments.filter((comment) => comment.id !== commentId));
    } catch (error) {
      console.error('댓글 삭제 중 오류가 발생했습니다.', error);
      alert('댓글 삭제 중 문제가 발생했습니다.');
    }
  };

  // 로딩 중일 때 로딩 메시지 출력
  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return <div>게시글을 불러오는 중...</div>;
  }

  return (
    <div className="freeboard-detail">
      <h2>{post.title}</h2>
      <p>{post.content}</p>
      <p>작성자: {post.author || '작성자 없음'}</p>
      <p>작성일: {new Date(post.date).toLocaleString()}</p>
      <p>조회수: {post.viewCount || 0}</p>

      <div>
        <button onClick={handleEditPost}>수정</button> {/* handleEditPost 정의됨 */}
        <button onClick={handleDeletePost}>삭제</button>
      </div>

      <h3>댓글</h3>
      {comments.length > 0 ? (
        comments.map((comment) => (
          <div key={comment.id}>
            <p>{comment.content}</p>
            <p>작성자: {comment.author}</p>
            <p>작성일: {new Date(comment.date).toLocaleString()}</p>
            <div>
              <button onClick={() => handleDeleteComment(comment.id)}>댓글 삭제</button>
            </div>
          </div>
        ))
      ) : (
        <p>댓글이 없습니다.</p>
      )}

      <div>
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="댓글을 입력하세요"
        />
        <button onClick={handleAddComment}>댓글 추가</button>
      </div>
    </div>
  );
};

export default FreeBoardDetailPage;
