import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // 페이지가 처음 로드될 때 localStorage에 저장된 토큰을 확인
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true); // 토큰이 있으면 로그인 상태로 설정
    }
  }, []); // 빈 배열로 설정하여 컴포넌트가 처음 로드될 때만 실행

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', { username, password });
      localStorage.setItem('token', response.data.token); // 로그인 성공 시 토큰 저장
      setIsLoggedIn(true);  // 로그인 상태 설정
      navigate('/');  // 메인 페이지로 리다이렉트
    } catch (error) {
      console.error('로그인 실패:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');  // 로그아웃 시 토큰 삭제
    setIsLoggedIn(false);  // 로그인 상태 해제
    navigate('/login');  // 로그인 페이지로 이동
  };

  return (
    <div>
      <h2>로그인</h2>
      {!isLoggedIn ? (
        <form onSubmit={handleLogin}>
          <div>
            <label>사용자명</label>
            <input 
              type="text" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
            />
          </div>
          <div>
            <label>비밀번호</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
            />
          </div>
          <button type="submit">로그인</button>
        </form>
      ) : (
        <div>
          <h3>로그인 성공!</h3>
          <button onClick={handleLogout}>로그아웃</button>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
