import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavContainer = ({ path, navImage, navText, hiddenPaths = [] }) => {
  const location = useLocation();
  const isHidden = hiddenPaths.some((hiddenPath) => location.pathname.startsWith(hiddenPath));

  return (
    !isHidden && (
      <div className="nav-container">
        <Link to={path} className="nav-item">
          <img src={navImage} alt="네비게이션 이미지" className="nav-image" />
          <div className="nav-text">
            {navText.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        </Link>
      </div>
    )
  );
};

export default NavContainer;
