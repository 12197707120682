import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/regionpage.css'; // CSS 파일 임포트

const RegionPage = () => {
  const [billiardHalls, setBilliardHalls] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' }); // 정렬 상태

  useEffect(() => {
    const fetchBilliardHalls = async () => {
      try {
        const response = await axios.get('/api/region'); // 서버에서 데이터 불러오기
        setBilliardHalls(response.data); // 서버에서 불러온 데이터를 상태에 저장
      } catch (error) {
        console.error('Error fetching billiard halls:', error);
      }
    };

    fetchBilliardHalls();
  }, []);

  // 정렬 함수
  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...billiardHalls].sort((a, b) => {
      if (key === 'id') {
        // 번호(id)를 기준으로 정렬
        return direction === 'asc' ? a.id - b.id : b.id - a.id;
      }
      if (key === 'region' || key === 'name' || key === 'address') {
        return direction === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      }
      return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
    });

    setBilliardHalls(sorted);
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲'; // 기본 화살표
  };

  return (
    <div className="region-container">
      <h2>지역별 당구장 랭킹</h2>
      <table className="ranking-table">
        <thead>
          <tr>
            <th onClick={() => sortData('id')} style={{ width: '10%' }}>
              번호 {renderArrow('id')}
            </th>
            <th onClick={() => sortData('region')} style={{ width: '15%' }}>
              지역 {renderArrow('region')}
            </th>
            <th onClick={() => sortData('name')} style={{ width: '25%' }}>
              당구장 이름 {renderArrow('name')}
            </th>
            <th onClick={() => sortData('address')} style={{ width: '30%' }}>
              주소 {renderArrow('address')}
            </th>
            <th style={{ width: '20%' }}>사이트 주소</th>
          </tr>
        </thead>
        <tbody>
          {billiardHalls.length > 0 ? (
            billiardHalls.map((hall) => (
              <tr key={hall.id}>
                {/* 번호는 index + 1을 사용해 표시, id 정렬에 따라 번호 표시 */}
                <td>{sortConfig.direction === 'asc' ? billiardHalls.findIndex(h => h.id === hall.id) + 1 : billiardHalls.length - billiardHalls.findIndex(h => h.id === hall.id)}</td>
                <td>{hall.region || '지역 없음'}</td>
                <td>{hall.name || '당구장 이름 없음'}</td>
                <td>{hall.address || '주소 없음'}</td>
                <td>
                  {hall.website ? (
                    <a href={hall.website} target="_blank" rel="noopener noreferrer">
                      {hall.website}
                    </a>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">현재 등록된 당구장이 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RegionPage;
