import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminNoticeManagePage = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [sortedAnnouncements, setSortedAnnouncements] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('/api/announcements');
        setAnnouncements(response.data);
        setSortedAnnouncements(response.data); // 초기 데이터를 sortedAnnouncements에도 저장
      } catch (error) {
        console.error('Error fetching announcements:', error);
        alert('공지사항을 불러오는 중 문제가 발생했습니다.');
      }
    };

    fetchAnnouncements();
  }, []);

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    if (!token) return alert('로그인이 필요합니다.');

    try {
      await axios.delete(`/api/announcements/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAnnouncements((prev) => prev.filter((a) => a.id !== id));
      setSortedAnnouncements((prev) => prev.filter((a) => a.id !== id)); // 삭제 후 정렬된 데이터도 업데이트
    } catch (error) {
      console.error('공지사항 삭제 중 오류:', error);
      alert('공지사항 삭제 중 문제가 발생했습니다.');
    }
  };

  // 정렬 함수 (UI에서만 정렬 처리)
  const sortAnnouncements = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...announcements].sort((a, b) => {
      if (key === 'date') {
        return direction === 'asc'
          ? new Date(a[key]) - new Date(b[key])
          : new Date(b[key]) - new Date(a[key]);
      } else if (key === 'title') {
        return direction === 'asc'
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
      }
    });

    setSortedAnnouncements(sorted); // 정렬된 데이터를 UI에만 반영
  };

  return (
    <div>
      <h2>공지사항 관리</h2>
      <button onClick={() => navigate('/admin/notice/new')}>공지 추가</button>
      <table>
        <thead>
          <tr>
            <th onClick={() => sortAnnouncements('id')}>
              번호 {sortConfig.key === 'id' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲'}
            </th>
            <th onClick={() => sortAnnouncements('title')}>
              제목 {sortConfig.key === 'title' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲'}
            </th>
            <th onClick={() => sortAnnouncements('date')}>
              날짜 {sortConfig.key === 'date' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : '▲'}
            </th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {sortedAnnouncements.map((announcement, index) => (
            <tr key={announcement.id}>
              {/* 삭제 후에도 정렬 상태 유지 */}
              <td>{sortConfig.direction === 'asc' ? index + 1 : sortedAnnouncements.length - index}</td>
              <td>{announcement.title}</td>
              <td>{new Date(announcement.date).toLocaleDateString()}</td>
              <td>
                <button onClick={() => navigate(`/admin/notice/edit/${announcement.id}`)}>수정</button>
                <button onClick={() => handleDelete(announcement.id)}>삭제</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminNoticeManagePage;
