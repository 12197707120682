import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import '../../css/noticeDetailPage.css'; // CSS 파일 연결

const NoticeDetailPage = () => {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await axios.get(`/api/announcements/${id}`);
        setAnnouncement(response.data);
      } catch (error) {
        console.error('Error fetching announcement:', error);
        setError('공지사항을 불러오는 중 문제가 발생했습니다.');
      }
    };

    fetchAnnouncement();
  }, [id]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!announcement) {
    return <p>공지사항을 불러오는 중입니다...</p>;
  }

  return (
    <div className="notice-detail-container">
      <div className="notice-detail-card">
        <table className="notice-table">
          <tbody>
            <tr>
              <td className="notice-label">제목</td>
              <td className="notice-title">{announcement.title}</td>
            </tr>
            <tr>
              <td className="notice-label">작성 날짜</td>
              <td className="notice-date">{new Date(announcement.date).toLocaleDateString()}</td>
            </tr>
            <tr>
              <td className="notice-label">내용</td>
              <td className="notice-content">{announcement.content}</td>
            </tr>
          </tbody>
        </table>
        <div className="back-link-container">
          <Link to="/notice" className="back-link">
            공지사항 목록으로 돌아가기
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoticeDetailPage;
