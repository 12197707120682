import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminHandicapManagePage = () => {
  const [handicapScores, setHandicapScores] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });

  useEffect(() => {
    const fetchHandicapScores = async () => {
      try {
        const token = localStorage.getItem('token'); // JWT 토큰 가져오기
        if (!token) {
          console.error('No token found');
          return;
        }

        const response = await axios.get('/api/handicap-scores', {
          headers: { Authorization: `Bearer ${token}` }
        });

        const data = Array.isArray(response.data) ? response.data : [];
        setHandicapScores(data);
      } catch (error) {
        console.error('Error fetching handicap scores:', error);
        setHandicapScores([]);
      }
    };

    fetchHandicapScores();
  }, []);

  const handleDeleteScore = async (scoreId) => {
    const confirmDelete = window.confirm('정말로 삭제하시겠습니까?');
    if (!confirmDelete) return;
    
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`/api/handicap-scores/${scoreId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      alert('점수가 삭제되었습니다.');
      setHandicapScores((prevScores) => prevScores.filter((score) => score.id !== scoreId));
    } catch (error) {
      console.error('Error deleting score:', error.response ? error.response.data.message : error.message);
      alert('점수 삭제 중 오류가 발생했습니다.');
    }
  };  

  const handleStatusChange = async (scoreId, status) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/handicap-scores/${scoreId}/status`, { status }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('상태가 변경되었습니다.');
      setHandicapScores((prevScores) =>
        prevScores.map((score) => (score.id === scoreId ? { ...score, status } : score))
      );
    } catch (error) {
      console.error('Error updating status:', error);
      alert('상태 변경 중 오류가 발생했습니다.');
    }
  };

  // 정렬 함수
  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...handicapScores].sort((a, b) => {
      if (key === 'id') {
        return direction === 'asc' ? a.id - b.id : b.id - a.id;
      } else if (key === 'author') {
        return direction === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      } else if (key === 'date') {
        return direction === 'asc' ? new Date(a[key]) - new Date(b[key]) : new Date(b[key]) - new Date(a[key]);
      } else {
        return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
      }
    });

    setHandicapScores(sorted);
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲'; // 기본 화살표
  };

  return (
    <div>
      <h1>핸디 점수 관리</h1>
      <table>
        <thead>
          <tr>
            <th onClick={() => sortData('id')}>번호 {renderArrow('id')}</th>
            <th onClick={() => sortData('author')}>글쓴이 {renderArrow('author')}</th>
            <th onClick={() => sortData('score4')}>4구 점수 {renderArrow('score4')}</th>
            <th onClick={() => sortData('score3')}>3구 점수 {renderArrow('score3')}</th>
            <th onClick={() => sortData('highRun4')}>4구 하이런 {renderArrow('highRun4')}</th>
            <th onClick={() => sortData('highRun3')}>3구 하이런 {renderArrow('highRun3')}</th>
            <th onClick={() => sortData('date')}>작성 날짜 {renderArrow('date')}</th>
            <th>상태 변경</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {handicapScores.length > 0 ? (
            handicapScores.map((score, index) => (
              <tr key={score.id}>
                {/* 번호는 index + 1을 사용해 표시, id 정렬에 따라 번호 표시 */}
                <td>{sortConfig.direction === 'asc' ? index + 1 : handicapScores.length - index}</td>
                <td>{score.author}</td>
                <td>{score.score4}</td>
                <td>{score.score3}</td>
                <td>{score.highRun4}</td>
                <td>{score.highRun3}</td>
                <td>{new Date(score.date).toLocaleDateString()}</td>
                <td>
                  <select
                    value={score.status || '진행 중'}
                    onChange={(e) => handleStatusChange(score.id, e.target.value)}
                  >
                    <option value="진행 중">진행 중</option>
                    <option value="등록 완료">등록 완료</option>
                  </select>
                </td>
                <td>
                  <button onClick={() => handleDeleteScore(score.id)}>삭제</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">등록된 핸디 점수가 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminHandicapManagePage;
