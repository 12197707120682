import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // useNavigate 훅 추가

const HandicapScoreWritePage = () => {
  const [newHandicapScore, setNewHandicapScore] = useState({
    score4: '',
    score3: '',
    highRun4: '',
    highRun3: '',
  });

  const navigate = useNavigate();  // useNavigate 훅 사용

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewHandicapScore({
      ...newHandicapScore,
      [name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const formData = {
      score4: parseInt(newHandicapScore.score4, 10),  // 숫자로 변환
      score3: parseInt(newHandicapScore.score3, 10),  // 숫자로 변환
      highRun4: parseInt(newHandicapScore.highRun4, 10),  // 숫자로 변환
      highRun3: parseInt(newHandicapScore.highRun3, 10),  // 숫자로 변환
    };

    try {
      const response = await axios.post('/api/handicap-scores', formData, config);
      console.log('Score submitted successfully:', response.data);
      
      // 점수 등록 완료 경고창 띄우기
      window.alert('점수 등록 완료');
      
      // 경고창 확인 후 리다이렉트
      navigate('/handicap-scores');  // 리다이렉트 경로 설정

    } catch (error) {
      console.error('Error writing score:', error.response ? error.response.data : error);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <label>
        4구 점수:
        <input type="number" name="score4" value={newHandicapScore.score4} onChange={handleChange} required />
      </label>
      <label>
        3구 점수:
        <input type="number" name="score3" value={newHandicapScore.score3} onChange={handleChange} required />
      </label>
      <label>
        4구 최고점수:
        <input type="number" name="highRun4" value={newHandicapScore.highRun4} onChange={handleChange} required />
      </label>
      <label>
        3구 최고점수:
        <input type="number" name="highRun3" value={newHandicapScore.highRun3} onChange={handleChange} required />
      </label>
      <button type="submit">점수 등록</button>
    </form>
  );
};

export default HandicapScoreWritePage;
