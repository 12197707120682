import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/reset-password', { token, newPassword });
      alert(response.data.message);
      navigate('/login');
    } catch (error) {
      console.error('Error resetting password:', error);
      alert(error.response.data.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>비밀번호 재설정</h2>
      <label>
        새로운 비밀번호:
        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
      </label>
      <button type="submit">재설정</button>
    </form>
  );
};

export default ResetPasswordPage;
