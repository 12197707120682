import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Link를 임포트하여 유료회원룸으로 링크 추가
import '../../css/signup.css'; // CSS 파일 임포트

const SignupPage = () => {
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(null); // 아이디 사용 가능 여부 상태
  const [checkingUsername, setCheckingUsername] = useState(false); // 중복 확인 중인지 여부
  const navigate = useNavigate();

  // 아이디 중복 확인 함수
  const checkUsernameAvailability = async () => {
    if (!username) {
      setMessage('아이디를 입력하세요.');
      return;
    }

    setCheckingUsername(true); // 중복 확인 중 상태 설정
    try {
      const response = await axios.get(`/api/check-username?username=${username}`);
      setMessage(response.data.message);
      setIsUsernameAvailable(true); // 아이디 사용 가능
    } catch (error) {
      setMessage(error.response?.data?.message || '중복 확인 실패');
      setIsUsernameAvailable(false); // 아이디 사용 불가
    } finally {
      setCheckingUsername(false); // 중복 확인 완료
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 비밀번호 확인
    if (password !== confirmPassword) {
      setMessage('비밀번호가 일치하지 않습니다.');
      return;
    }

    // 아이디 중복 확인 필요
    if (!isUsernameAvailable) {
      setMessage('아이디 중복 확인을 먼저 해주세요.');
      return;
    }

    // 회원가입 요청
    try {
      const response = await axios.post('/api/signup', { 
        username, 
        phone, 
        email, 
        password
      });
      console.log('Signup response:', response.data);
      setMessage('회원가입 성공');
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error('회원가입 오류:', error);
      setMessage('회원가입에 실패했습니다.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="signup-form">
      <h2>회원가입</h2>
      {message && <p className="message">{message}</p>}
      <label>
        아이디:
        <input 
          type="text" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          disabled={checkingUsername} // 중복 확인 중일 때 입력 비활성화
        />
        <button 
          type="button" 
          onClick={checkUsernameAvailability} 
          disabled={checkingUsername || !username} // 입력 없거나 중복 확인 중일 때 비활성화
        >
          {checkingUsername ? '확인 중...' : '아이디 중복 확인'}
        </button>
      </label>
      {/* 아이디 중복 여부에 따라 메시지 출력 */}
      {isUsernameAvailable === false && <p className="error">아이디가 이미 사용 중입니다.</p>}
      {isUsernameAvailable === true && <p className="success">아이디를 사용할 수 있습니다.</p>}

      <label>
        전화번호:
        <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
      </label>
      <label>
        이메일:
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </label>
      <label>
        비밀번호:
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </label>
      <label>
        비밀번호 확인:
        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
      </label>

      {/* 멘트 및 유료회원룸 링크 */}
      <p className="info-message">
        4구점수, 3구점수, 4구하이런, 3구하이런에 대한 개인정보 등록은 핸디점수등록에서 작성하실 수 있습니다.
        <Link to="/handicap-scores"> 핸디점수등록 바로가기</Link>
      </p>

      <button type="submit" disabled={!isUsernameAvailable}>
        가입
      </button>
    </form>
  );
};

export default SignupPage;
