import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminRegionManagePage = () => {
  const [billiardHalls, setBilliardHalls] = useState([]);
  const [sortedBilliardHalls, setSortedBilliardHalls] = useState([]);
  const [newBilliardHall, setNewBilliardHall] = useState({
    지역: '',
    '당구장 이름': '',
    '당구장 주소': '',
    '사이트 주소': ''
  });
  const [editHallId, setEditHallId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBilliardHalls = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/region');
        setBilliardHalls(response.data);
        setSortedBilliardHalls(response.data);
      } catch (error) {
        console.error('Error fetching billiard halls:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBilliardHalls();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBilliardHall({
      ...newBilliardHall,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const token = localStorage.getItem('token');
  
    if (!token) {
      console.error('No token found');
      setLoading(false);
      return;
    }
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
  
    try {
      const formData = {
        region: newBilliardHall['지역'],
        name: newBilliardHall['당구장 이름'],
        address: newBilliardHall['당구장 주소'],
        website: newBilliardHall['사이트 주소'] || ''
      };
  
      let response;
      if (editHallId) {
        response = await axios.put(`/api/region/${editHallId}`, formData, config);
        setBilliardHalls(prevHalls =>
          prevHalls.map(hall => hall.id === editHallId ? response.data : hall)
        );
      } else {
        response = await axios.post('/api/region', formData, config);
        setBilliardHalls([...billiardHalls, response.data]);
      }
  
      setNewBilliardHall({
        지역: '',
        '당구장 이름': '',
        '당구장 주소': '',
        '사이트 주소': ''
      });
      setEditHallId(null);
  
      navigate('/admin/region-manage', { replace: true });
     window.location.reload();
    } catch (error) {
      console.error('Error saving billiard hall:', error);
      alert(`Error saving billiard hall: ${error.response ? error.response.data.message : error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (hall) => {
    if (window.confirm('수정하시겠습니까?')) {
      setEditHallId(hall.id);
      setNewBilliardHall({
        지역: hall.region || '',
        '당구장 이름': hall.name || '',
        '당구장 주소': hall.address || '',
        '사이트 주소': hall.website || '' // 사이트 주소 설정
      });
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      setLoading(true);
      const token = localStorage.getItem('token');

      if (!token) {
        console.error('No token found');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        await axios.delete(`/api/region/${id}`, config);
        setBilliardHalls(billiardHalls.filter(hall => hall.id !== id));
        setSortedBilliardHalls(billiardHalls.filter(hall => hall.id !== id));
      } catch (error) {
        console.error('Error deleting billiard hall:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sorted = [...billiardHalls].sort((a, b) => {
      if (key === 'id') {
        return direction === 'asc' ? a.id - b.id : b.id - a.id;
      }
      if (key === 'region' || key === 'name' || key === 'address') {
        return direction === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      }
      return direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
    });
    setSortedBilliardHalls(sorted);
  };

  const renderArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '▲';
  };

  return (
    <div>
      <h2>{editHallId ? '당구장 수정' : '당구장 정보 추가'}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>지역</label>
          <input
            type="text"
            name="지역"
            value={newBilliardHall['지역']}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>당구장 이름</label>
          <input
            type="text"
            name="당구장 이름"
            value={newBilliardHall['당구장 이름']}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>주소</label>
          <input
            type="text"
            name="당구장 주소"
            value={newBilliardHall['당구장 주소']}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>사이트 주소</label>
          <input
            type="url"
            name="사이트 주소"
            value={newBilliardHall['사이트 주소']}
            onChange={handleChange}
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? '처리 중...' : editHallId ? '수정 완료' : '추가'}
        </button>
      </form>

      {loading ? (
        <p>로딩 중...</p>
      ) : (
        <>
          <h2>현재 등록된 당구장</h2>
          <table>
            <thead>
              <tr>
                <th onClick={() => sortData('id')} style={{ width: '10%' }}>
                  번호 {renderArrow('id')}
                </th>
                <th onClick={() => sortData('region')} style={{ width: '15%' }}>
                  지역 {renderArrow('region')}
                </th>
                <th onClick={() => sortData('name')} style={{ width: '25%' }}>
                  당구장 이름 {renderArrow('name')}
                </th>
                <th onClick={() => sortData('address')} style={{ width: '30%' }}>
                  주소 {renderArrow('address')}
                </th>
                <th style={{ width: '20%' }}>사이트 주소</th>
                <th style={{ width: '10%' }}>관리</th>
              </tr>
            </thead>
            <tbody>
              {sortedBilliardHalls.length > 0 ? (
                sortedBilliardHalls.map((hall, index) => (
                  <tr key={hall.id}>
                    <td>{sortConfig.direction === 'asc' ? index + 1 : sortedBilliardHalls.length - index}</td>
                    <td>{hall.region || '지역 없음'}</td>
                    <td>{hall.name || '이름 없음'}</td>
                    <td>{hall.address || '주소 없음'}</td>
                    <td>
                      {hall.website ? (
                        <a href={hall.website} target="_blank" rel="noopener noreferrer">
                          {hall.website}
                        </a>
                      ) : (
                        '' // 사이트 주소가 없으면 빈 문자열
                      )}
                    </td>
                    <td>
                      <button onClick={() => handleEdit(hall)}>수정</button>
                      <button onClick={() => handleDelete(hall.id)}>삭제</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">등록된 당구장이 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AdminRegionManagePage;
