import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const AdminNoticeEditPage = () => {
  const { id } = useParams();  // URL에서 id를 가져옵니다.
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // id가 존재하면 수정 모드이므로 해당 공지사항 데이터를 가져옵니다.
    if (id) {
      axios
        .get(`/api/announcements/${id}`)
        .then((response) => {
          const announcement = response.data;
          setTitle(announcement.title);
          setContent(announcement.content);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching announcement:', error);
          alert('공지사항을 불러오는 중 문제가 발생했습니다.');
        });
    } else {
      setIsLoading(false);  // id가 없으면 로딩을 종료합니다.
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      alert('로그인이 필요합니다.');
      return;
    }

    try {
      const announcementData = { title, content };

      // id가 존재하면 수정 요청, 그렇지 않으면 추가 요청을 보냅니다.
      if (id) {
        await axios.put(`/api/announcements/${id}`, announcementData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        alert('공지사항이 수정되었습니다.');
      } else {
        await axios.post('/api/announcements', announcementData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        alert('공지사항이 작성되었습니다.');
      }

      // 수정 또는 작성 후 관리자 공지사항 관리 페이지로 리다이렉트
      navigate('/admin/notice-manage');

    } catch (error) {
      console.error('Error saving announcement:', error);
      alert('공지사항 저장 중 문제가 발생했습니다.');
    }
  };

  if (isLoading) {
    return <p>로딩 중입니다...</p>;
  }

  return (
    <div>
      <h2>{id ? '공지사항 수정' : '공지사항 작성'}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">제목</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="content">내용</label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </div>
        <button type="submit">{id ? '수정 완료' : '작성 완료'}</button>
      </form>
    </div>
  );
};

export default AdminNoticeEditPage;
